.App {
  text-align: center;
  min-width: 1200px;
  /* max-width: 2000px; */
}

.mt-2 {
  margin-top: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2d333f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
}

textarea {
  display: block;
  width: 100%;
  max-width: 600px;
  /* height: calc(2.25rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 50px;
}

.palette {
  width: 200px;
  height: 700px;
}

.all-palette {
  display: flex;
}

button {
  width: 350px;
  text-decoration: none;
  display: inline-block;
  padding: 12px 12px;
  margin: 100px 20px 0 20px;
  border-radius: 15px;
  background-image: linear-gradient(
    45deg,
    #d76ad0 0%,
    #e844e0 50%,
    #780056 100%
  );
  background-position: 100% 0;
  background-size: 200% 200%;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 300;
  color: white;
  box-shadow: 0 16px 32px 0 rgba(0, 40, 120, 0.35);
  transition: 0.5s;
}

.btn-last {
  margin-top: 50px;
  margin-bottom: 200px;
}

a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

a:hover {
  text-decoration: underline;
}

.input-last {
  width: 900px;
}

.flex-container-colunm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.pin-btn {
  margin-top: 590px;
  background-image: linear-gradient(
    45deg,
    #d4cdd4 0%,
    #9b919b 50%,
    #1d1b1c 100%
  );
}

.btn-change-colors {
  margin-top: 100px;
}
